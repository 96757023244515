<script setup>
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { useRoute, useRouter } from 'vue-router'


const route = useRoute()
const router = useRouter()

definePage({ meta: { layout: 'blank' } })

const form = ref({
  newPassword: '',
  confirmPassword: '',
})

const token = route.params.token
const context = Number(route.query.reset)
const email = route.query.email

const isPasswordVisible = ref(false)
const isConfirmPasswordVisible = ref(false)
const formValidation = ref({})
const passwordError = ref('')
const confirmPasswordError = ref('')
const passwordChanged = ref(false)

const onSubmit = () => {
  formValidation.value?.validate().then(valide => {
    if (valide.valid === true) {
      $api('/reset-password', {
        method: 'POST',
        body: {
          email: email,
          password: form.value.newPassword,
          'password_confirmation': form.value.confirmPassword,
          token: token,
        },
      })
    } else {
      throw new Error('Invalid form')
    }
  }).then(() => {
    passwordChanged.value = true
    setTimeout(() => {
      router.push({ name: 'login' })
    }, 1000)
  }).catch(errors => {
    if (errors.message === 'Invalid form') {
      console.error(errors.message)

      return
    }
    if (errors.response.status === 422) {
      Object.entries(errors.response._data.errors).forEach(entry => {
        if (entry[0] === 'password')
          passwordError.value = entry[1]
        if (entry[0] === 'confirmPassword')
          passwordError.value = entry[1]
      })
    }
  })
}
</script>

<template>
  <VAlert
    v-if="passwordChanged"
    color="success"
  >
    <VRow>
      <VCol align="center">
        <h2>Votre mot de passe a bien été modifié</h2>
      </VCol>
    </VRow>
  </VAlert>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <VNodeRenderer
        :nodes="h('div', { innerHTML: authV1TopShape })"
        class="text-primary auth-v1-top-shape d-none d-sm-block"
      />

      <VNodeRenderer
        :nodes="h('div', { innerHTML: authV1BottomShape })"
        class="text-primary auth-v1-bottom-shape d-none d-sm-block"
      />

      <VCard
        class="auth-card pa-4"
        max-width="448"
      >
        <VCardItem class="justify-center">
          <template #prepend>
            <div class="d-flex">
              <VNodeRenderer :nodes="themeConfig.app.logo" />
            </div>
          </template>

          <VCardTitle class="font-weight-bold text-capitalize text-h3 py-1">
            {{ themeConfig.app.title }}
          </VCardTitle>
        </VCardItem>

        <VCardText class="pt-2">
          <h4
            class="text-h4 mb-1"
            v-if="context !== 0"
          >
            Changer de mot de passe 🔒
          </h4>
          <h4
            class="text-h4 mb-1"
            v-else
          >
            Configurer votre mot de passe 🔒
          </h4>
          <p class="mb-0">
            pour <span class="font-weight-bold">{{ email }}</span>
          </p>
        </VCardText>

        <VCardText>
          <VForm
            ref="formValidation"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="form.newPassword"
                  autofocus
                  label="Nouveau mot de passe"
                  placeholder="············"
                  :rules="[vRequiredValidator]"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :error-messages="passwordError"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.confirmPassword"
                  label="Confirmation du mot de passe"
                  placeholder="············"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isConfirmPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  :rules="[vRequiredValidator]"
                  :error-messages="confirmPasswordError"
                  @click:append-inner="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                />
              </VCol>

              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                >
                  Changer
                </VBtn>
              </VCol>

              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  :to="{ name: 'login' }"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    class="flip-in-rtl"
                  />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
