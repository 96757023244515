import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router/auto'
import * as Sentry from "@sentry/vue";


// const routes = [
//   {
//     name: "EditOrder", 
//     path: "/toto/:id", 
//     component: EditOrder, 
//     props: true 
//   }, 
// ]

function recursiveLayouts(route) {
  if (route.children) {
    for (let i = 0; i < route.children.length; i++)
      route.children[i] = recursiveLayouts(route.children[i])

    return route
  }

  return setupLayouts([route])[0]
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash)
      return { el: to.hash, behavior: 'smooth', top: 60 }

    return { top: 0 }
  },
  extendRoutes: pages => [
    ...[...pages].map(route => recursiveLayouts(route)),
    //...routes
  ],
})

const unprotectedRoutes = [
  'login', 'forgot-password', 'first-connexion', 'reset-password-token'
];

router.beforeEach((to, from) => {

  const store = userStore()

  if (unprotectedRoutes.every(el => el !== to.name) && store.authenticated === false)
    return { name: 'login' }
})

export { router }
export default function (app) {

  app.use(router);
  initApp(app)
}

async function initApp(app) {

  /**
   * J'attends que le routeur soit prêt pour éviter un undefined sur router.currentRoute.value.name
   */
  await router.isReady();

 /**
   * All api routes are protected by sanctum. When we make a request
   * in /src/utils/api.js if we have a 401 we are automaticly redirected
   * to login page.
   * 
   * At site load I make a request to be redirected on login page if needed
   */
  const checkAuth = () => {
    $api('/api/auth', {
      method: 'GET',
      credentials: 'include'
    })
  }

  if (unprotectedRoutes.every(el => el !== router.currentRoute.value.name)) {
    checkAuth()
  }

  if (import.meta.env.mode == 'production') {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_DSN_SENTRY,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
   }
}
