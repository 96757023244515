import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

//TODO put in stores folder
const userStore = defineStore('useStore', {
  state: () => ({
    user: useLocalStorage('pinia-store-user', {}),
    //Used for resotre impersonated user
    oldUser: useLocalStorage('pinia-store-oldUser', {}),
    authenticated: useLocalStorage('pinia-store-authenticated', false),
  }),
})

export { userStore }
