import _definePage_default_0 from '/srv/http/demo/frontend-tsd-retouches/src/pages/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/srv/http/demo/frontend-tsd-retouches/src/pages/first-connexion.vue?definePage&vue'
import _definePage_default_3 from '/srv/http/demo/frontend-tsd-retouches/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_4 from '/srv/http/demo/frontend-tsd-retouches/src/pages/login.vue?definePage&vue'
import _definePage_default_5 from '/srv/http/demo/frontend-tsd-retouches/src/pages/reset-password/[token].vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/brands',
    /* internal name: 'brands' */
    /* no component */
    children: [
      {
        path: '',
        name: 'brands',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/index.vue'),
        /* no children */
      },
      {
        path: ':brandid',
        /* internal name: 'brands-brandid' */
        /* no component */
        children: [
          {
            path: '',
            name: 'brands-brandid',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/index.vue'),
            /* no children */
          },
          {
            path: 'abbreviations',
            /* internal name: 'brands-brandid-abbreviations' */
            /* no component */
            children: [
              {
                path: 'create',
                name: 'brands-brandid-abbreviations-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/abbreviations/create.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'clothes',
            /* internal name: 'brands-brandid-clothes' */
            /* no component */
            children: [
              {
                path: 'create',
                name: 'brands-brandid-clothes-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/clothes/create.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'shops',
            /* internal name: 'brands-brandid-shops' */
            /* no component */
            children: [
              {
                path: '',
                name: 'brands-brandid-shops',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/shops/index.vue'),
                /* no children */
              },
              {
                path: 'create',
                name: 'brands-brandid-shops-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/shops/create.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'update',
            name: 'brands-brandid-update',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/update.vue'),
            /* no children */
          },
          {
            path: 'users',
            /* internal name: 'brands-brandid-users' */
            /* no component */
            children: [
              {
                path: ':userid',
                /* internal name: 'brands-brandid-users-userid' */
                /* no component */
                children: [
                  {
                    path: 'update',
                    name: 'brands-brandid-users-userid-update',
                    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/users/[userid]/update.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'create',
                name: 'brands-brandid-users-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/[brandid]/users/create.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'create',
        name: 'brands-create',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/brands/create.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/change-password.vue'),
    /* no children */
  },
  {
    path: '/clothes',
    /* internal name: 'clothes' */
    /* no component */
    children: [
      {
        path: ':clotheid',
        /* internal name: 'clothes-clotheid' */
        /* no component */
        children: [
          {
            path: '',
            name: 'clothes-clotheid',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/clothes/[clotheid]/index.vue'),
            /* no children */
          },
          {
            path: 'prestations',
            /* internal name: 'clothes-clotheid-prestations' */
            /* no component */
            children: [
              {
                path: 'create',
                name: 'clothes-clotheid-prestations-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/clothes/[clotheid]/prestations/create.vue'),
                /* no children */
              },
              {
                path: 'createoutofglossary',
                name: 'clothes-clotheid-prestations-createoutofglossary',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/clothes/[clotheid]/prestations/createoutofglossary.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'update',
            name: 'clothes-clotheid-update',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/clothes/[clotheid]/update.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/edit-order',
    /* internal name: 'edit-order' */
    /* no component */
    children: [
      {
        path: ':orderid',
        /* internal name: 'edit-order-orderid' */
        /* no component */
        children: [
          {
            path: '',
            name: 'edit-order-orderid',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/edit-order/[orderid]/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/first-connexion',
    name: 'first-connexion',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/first-connexion.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_3
  ),
  {
    path: '/franchises',
    /* internal name: 'franchises' */
    /* no component */
    children: [
      {
        path: '',
        name: 'franchises',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/franchises/index.vue'),
        /* no children */
      },
      {
        path: 'create',
        name: 'franchises-create',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/franchises/create.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/holding',
    /* internal name: 'holding' */
    /* no component */
    children: [
      {
        path: '',
        name: 'holding',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/holding/index.vue'),
        /* no children */
      },
      {
        path: ':holdingid',
        /* internal name: 'holding-holdingid' */
        /* no component */
        children: [
          {
            path: 'brands',
            /* internal name: 'holding-holdingid-brands' */
            /* no component */
            children: [
              {
                path: '',
                name: 'holding-holdingid-brands',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/holding/[holdingid]/brands/index.vue'),
                /* no children */
              },
              {
                path: 'create',
                name: 'holding-holdingid-brands-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/holding/[holdingid]/brands/create.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'show',
            name: 'holding-holdingid-show',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/holding/[holdingid]/show.vue'),
            /* no children */
          },
          {
            path: 'update',
            name: 'holding-holdingid-update',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/holding/[holdingid]/update.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'create',
        name: 'holding-create',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/holding/create.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_4
  ),
  {
    path: '/orders-clothes-listing',
    name: 'orders-clothes-listing',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/orders-clothes-listing.vue'),
    /* no children */
  },
  {
    path: '/orders-listing',
    name: 'orders-listing',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/orders-listing.vue'),
    /* no children */
  },
  {
    path: '/parameters',
    name: 'parameters',
    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/parameters.vue'),
    /* no children */
  },
  {
    path: '/reset-password',
    /* internal name: 'reset-password' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':token',
        name: 'reset-password-token',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/reset-password/[token].vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  {
    path: '/shops',
    /* internal name: 'shops' */
    /* no component */
    children: [
      {
        path: '',
        name: 'shops',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/shops/index.vue'),
        /* no children */
      },
      {
        path: ':shopid',
        /* internal name: 'shops-shopid' */
        /* no component */
        children: [
          {
            path: '',
            name: 'shops-shopid',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/shops/[shopid]/index.vue'),
            /* no children */
          },
          {
            path: 'update',
            name: 'shops-shopid-update',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/shops/[shopid]/update.vue'),
            /* no children */
          },
          {
            path: 'users',
            /* internal name: 'shops-shopid-users' */
            /* no component */
            children: [
              {
                path: ':userid',
                /* internal name: 'shops-shopid-users-userid' */
                /* no component */
                children: [
                  {
                    path: 'update',
                    name: 'shops-shopid-users-userid-update',
                    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/shops/[shopid]/users/[userid]/update.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'create',
                name: 'shops-shopid-users-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/shops/[shopid]/users/create.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/users',
    /* internal name: 'users' */
    /* no component */
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users/index.vue'),
        /* no children */
      },
      {
        path: ':userid',
        /* internal name: 'users-userid' */
        /* no component */
        children: [
          {
            path: 'update',
            name: 'users-userid-update',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users/[userid]/update.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'create',
        name: 'users-create',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users/create.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/users-pages',
    /* internal name: 'users-pages' */
    /* no component */
    children: [
      {
        path: 'brand',
        /* internal name: 'users-pages-brand' */
        /* no component */
        children: [
          {
            path: '',
            name: 'users-pages-brand',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/brand/index.vue'),
            /* no children */
          },
          {
            path: 'change-password',
            name: 'users-pages-brand-change-password',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/brand/change-password.vue'),
            /* no children */
          },
          {
            path: 'contact',
            name: 'users-pages-brand-contact',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/brand/contact.vue'),
            /* no children */
          },
          {
            path: 'parameters',
            name: 'users-pages-brand-parameters',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/brand/parameters.vue'),
            /* no children */
          },
          {
            path: 'shops',
            name: 'users-pages-brand-shops',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/brand/shops.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'shop',
        /* internal name: 'users-pages-shop' */
        /* no component */
        children: [
          {
            path: '',
            name: 'users-pages-shop',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/index.vue'),
            /* no children */
          },
          {
            path: 'arrival',
            name: 'users-pages-shop-arrival',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/arrival.vue'),
            /* no children */
          },
          {
            path: 'change-password',
            name: 'users-pages-shop-change-password',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/change-password.vue'),
            /* no children */
          },
          {
            path: 'contact',
            name: 'users-pages-shop-contact',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/contact.vue'),
            /* no children */
          },
          {
            path: 'departure',
            name: 'users-pages-shop-departure',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/departure.vue'),
            /* no children */
          },
          {
            path: 'order',
            name: 'users-pages-shop-order',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/order.vue'),
            /* no children */
          },
          {
            path: 'orders-clothes-listing',
            name: 'users-pages-shop-orders-clothes-listing',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/orders-clothes-listing.vue'),
            /* no children */
          },
          {
            path: 'orders-listing',
            name: 'users-pages-shop-orders-listing',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/orders-listing.vue'),
            /* no children */
          },
          {
            path: 'parameters',
            name: 'users-pages-shop-parameters',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/shop/parameters.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'workshop',
        /* internal name: 'users-pages-workshop' */
        /* no component */
        children: [
          {
            path: '',
            name: 'users-pages-workshop',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/index.vue'),
            /* no children */
          },
          {
            path: 'arrival',
            name: 'users-pages-workshop-arrival',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/arrival.vue'),
            /* no children */
          },
          {
            path: 'change-password',
            name: 'users-pages-workshop-change-password',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/change-password.vue'),
            /* no children */
          },
          {
            path: 'contact',
            name: 'users-pages-workshop-contact',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/contact.vue'),
            /* no children */
          },
          {
            path: 'departure',
            name: 'users-pages-workshop-departure',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/departure.vue'),
            /* no children */
          },
          {
            path: 'invoices',
            name: 'users-pages-workshop-invoices',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/invoices.vue'),
            /* no children */
          },
          {
            path: 'orders-clothes-listing',
            name: 'users-pages-workshop-orders-clothes-listing',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/orders-clothes-listing.vue'),
            /* no children */
          },
          {
            path: 'orders-listing',
            name: 'users-pages-workshop-orders-listing',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/orders-listing.vue'),
            /* no children */
          },
          {
            path: 'parameters',
            name: 'users-pages-workshop-parameters',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/parameters.vue'),
            /* no children */
          },
          {
            path: 'pricings',
            name: 'users-pages-workshop-pricings',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/pricings.vue'),
            /* no children */
          },
          {
            path: 'shops',
            name: 'users-pages-workshop-shops',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/users-pages/workshop/shops.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/workshops',
    /* internal name: 'workshops' */
    /* no component */
    children: [
      {
        path: '',
        name: 'workshops',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/workshops/index.vue'),
        /* no children */
      },
      {
        path: ':workshopid',
        /* internal name: 'workshops-workshopid' */
        /* no component */
        children: [
          {
            path: '',
            name: 'workshops-workshopid',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/workshops/[workshopid]/index.vue'),
            /* no children */
          },
          {
            path: 'brands',
            /* internal name: 'workshops-workshopid-brands' */
            /* no component */
            children: [
              {
                path: ':brandid',
                /* internal name: 'workshops-workshopid-brands-brandid' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: 'workshops-workshopid-brands-brandid',
                    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/workshops/[workshopid]/brands/[brandid]/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'update',
            name: 'workshops-workshopid-update',
            component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/workshops/[workshopid]/update.vue'),
            /* no children */
          },
          {
            path: 'users',
            /* internal name: 'workshops-workshopid-users' */
            /* no component */
            children: [
              {
                path: ':userid',
                /* internal name: 'workshops-workshopid-users-userid' */
                /* no component */
                children: [
                  {
                    path: 'update',
                    name: 'workshops-workshopid-users-userid-update',
                    component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/workshops/[workshopid]/users/[userid]/update.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'create',
                name: 'workshops-workshopid-users-create',
                component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/workshops/[workshopid]/users/create.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'create',
        name: 'workshops-create',
        component: () => import('/srv/http/demo/frontend-tsd-retouches/src/pages/workshops/create.vue'),
        /* no children */
      }
    ],
  }
]
