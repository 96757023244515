<script setup>
import { router } from '@/plugins/1.router'
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'


definePage({ meta: { layout: 'blank' } })

const form = ref({ email: '' })
const emailError = ref('')
const formValidation = ref({})
const emailSend = ref(false)

const onSubmit = () => {
  formValidation.value?.validate().then(validation => {
    if (validation.valid === true) {
        return $api('/forgot-password', {
          method: 'POST',
          credentials: 'include',
          body: {
            email: form.value.email,
          },
        })
    } else {
      throw new Error('Invalid form')
    }
  }).then(() => {
    emailSend.value = true
    setTimeout(() => {
      router.push({ name: 'login' })
    }, 2000)
  }).catch(errors => {
    if(errors.message === 'Invalid form') {
      console.error(errors.message)

      return 
    }
    if (errors.response.status === 422) {
      Object.entries(errors.response._data.errors).forEach(entry => {
        if (entry[0] === 'email')
          emailError.value = entry[1]
      })
    }
  })
}
</script>

<template>
  <VAlert
    v-if="emailSend"
    color="success"
  >
    <VRow>
      <VCol align="center">
        <h2>Un email vous a été envoyé</h2>
      </VCol>
    </VRow>
  </VAlert>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <VNodeRenderer
        :nodes="h('div', { innerHTML: authV1TopShape })"
        class="text-primary auth-v1-top-shape d-none d-sm-block"
      />


      <VNodeRenderer
        :nodes="h('div', { innerHTML: authV1BottomShape })"
        class="text-primary auth-v1-bottom-shape d-none d-sm-block"
      />

      <VCard
        class="auth-card pa-4"
        max-width="448"
      >
        <VCardItem class="justify-center">
          <template #prepend>
            <div class="d-flex">
              <VNodeRenderer :nodes="themeConfig.app.logo" />
            </div>
          </template>

          <VCardTitle class="font-weight-bold text-capitalize text-h3 py-1">
            {{ themeConfig.app.title }}
          </VCardTitle>
        </VCardItem>

        <VCardText class="pt-2">
          <h4 class="text-h4 mb-1">
            Première connexion? 🔒
          </h4>
          <p class="mb-0">
            Entrez votre email nous allons vous envoyer les instructions à suivre.
          </p>
        </VCardText>

        <VCardText>
          <VForm
            ref="formValidation"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="form.email"
                  autofocus
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                  :error-messages="emailError"
                />
              </VCol>
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                >
                  Envoyer le lien
                </VBtn>
              </VCol>
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  :to="{ name: 'login' }"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    class="flip-in-rtl"
                  />
                  <span>Se connecter</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
