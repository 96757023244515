import { ofetch } from 'ofetch'
import { nextTick } from 'vue'

if (!import.meta.env.VITE_API_BASE_URL) 
  throw new Error("VITE_API_BASE_URL is not set")


/**
 * Here i avoid consecutive call on POST and PUT requests, in case user
 * spamming a button. Once the reponse is received we release the lock.
 */
let lastRequest = null
let lastRequestComplete = true

function resetThrottle() {
  lastRequest = null
  lastRequestComplete = true
}
function failOnConsecutiveRequest(request){
  if (lastRequestComplete === false && lastRequest == request) {
    throw new Error('Too many requests')
  } 
}
function handleRequestThrottle(options, request) {
  if (options.method === 'POST' || options.method === 'DELETE') {
    lastRequest = request
    lastRequestComplete = false
  }
}

async function fetchCsrfCookie() {
    await ofetch(import.meta.env.VITE_API_BASE_URL + '/sanctum/csrf-cookie',  {
      credentials : 'include'
    })
}

export const $api = ofetch.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  async onRequest({ request, options }) {
    // TODO give the hability to desactivate these feature
    failOnConsecutiveRequest(request)

    const accessToken = useCookie('accessToken').value

    handleRequestThrottle(options, request)

    options.credentials = 'include'

    options.headers = {
      ...options.headers,
      'X-Requested-With': 'XMLHttpRequest',
    }

    if (accessToken) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${accessToken}`,
      }
    }

    let xsrfToken = useCookie('XSRF-TOKEN').value
    if (!xsrfToken)
      await fetchCsrfCookie()

    options.headers = {
      ...options.headers,
      'X-XSRF-TOKEN': useCookie('XSRF-TOKEN').value,
    }
  },
  async onResponse({ request, response, options }) {
    /**
      * Recover from sesssion loose
      * TODO improve the functionality
      */
    if (import.meta.env.VITE_FRONTEND_URL === undefined) {
      throw new Error('VITE_FRONTEND_URL environnment variable not set')
    } 
    if (response.status === 401)
      window.location.href = import.meta.env.VITE_FRONTEND_URL + '/login'
    if (response.status === 419)
      await fetchCsrfCookie(options)

    resetThrottle()
  },
})
