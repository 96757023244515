<script setup>
import authV1BottomShape from '@images/svg/auth-v1-bottom-shape.svg?raw'
import authV1TopShape from '@images/svg/auth-v1-top-shape.svg?raw'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { useRouter } from 'vue-router'
import { VForm } from 'vuetify/components/VForm'

definePage({
  meta: {
    layout: 'blank',
    unauthenticatedOnly: true,
  },
})

const isPasswordVisible = ref(false)

const store = userStore()

const errors = ref({
  email: undefined,
  password: undefined,
})


const formValidation = ref()

const credentials = ref({
  email: '',
  password: '',
})

const rememberMe = ref(false)
const emailError = ref('')

const router = useRouter()

const onSubmit = () => {
  formValidation.value?.validate().then(validation => {
    if (validation.valid === true) {
      return $api('/login', {
        method: 'POST',
        body: {
          email: credentials.value.email,
          password: credentials.value.password,
          remember: rememberMe,
        },
      })
  } else {
      throw new Error('Invalid Form')
    }
  }).then(res => {
    store.user = res.user
    store.authenticated = true
  }).then(() => {
    if (store.user.is_tsd === true)
      return router.push({ name: 'root' })
    else if (store.user.brand_id !== null)
      return router.push({ name: 'users-pages-brand' })
    else if (store.user.shop_id !== null)
      return router.push({ name: 'users-pages-shop' })
    else if (store.user.workshop_id !== null)
      return router.push({ name: 'users-pages-workshop' })
    else
      throw new Error('Bad user type')
  }).catch(errors => {
    if (errors.response && errors.response.status === 422) {
      Object.entries(errors.response._data.errors).forEach(entry => {
        if (entry[0] === 'email')
          emailError.value = entry[1]
      })
    }
    if (errors.response && errors.response.status === 429) {
      emailError.value = errors.response._data.message
    }
  })
}
</script>

<template>
  <div class="auth-wrapper d-flex align-center justify-center pa-4">
    <div class="position-relative my-sm-16">
      <VNodeRenderer
        :nodes="h('div', { innerHTML: authV1TopShape })"
        class="text-primary auth-v1-top-shape d-none d-sm-block"
      />

      <VNodeRenderer
        :nodes="h('div', { innerHTML: authV1BottomShape })"
        class="text-primary auth-v1-bottom-shape d-none d-sm-block"
      />

      <VCard
        class="auth-card pa-4"
        max-width="448"
      >
        <VCardItem class="justify-center">
          <template #prepend>
            <div class="d-flex">
              <VNodeRenderer :nodes="themeConfig.app.logo" />
            </div>
          </template>

          <VCardTitle id="test123" class="text-capitalize text-h3 py-1">
            {{ themeConfig.app.title }}
          </VCardTitle>
        </VCardItem>

        <VCardText class="pt-1">
          <!-- <p class="text-h6 mb-1 text-center">
            Bienvenue chez :
          </p> -->
          <p class="text-h4 text-center"> 
            Textiles Services Développement
          </p>
          <p class="text-center">
            Retouches textiles centralisées
          </p>
        </VCardText>

        <VCardText>
          <VForm
            ref="formValidation"
            @submit.prevent="onSubmit"
          >
            <VRow>
              <VCol
                cols="12"
                class="cypress-input-container"
              >
                <AppTextField
                  v-model="credentials.email"
                  name="email"
                  autofocus
                  label="Email"
                  type="email"
                  placeholder="demo@comidev.fr"
                  :error-messages="emailError"
                  :rules="[vRequiredValidator]"
                  validate-on="submit"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="credentials.password"
                  label="Password"
                  name="password"
                  :rules="[vRequiredValidator]"
                  validate-on="submit"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
                <div class="d-flex align-center justify-space-between flex-wrap mt-2 mb-4">
                  <VCheckbox
                    v-model="rememberMe"
                    label="Se rappeler de moi"
                  />

                  <RouterLink
                    class="text-primary ms-2 mb-1"
                    :to="{ name: 'forgot-password' }"
                  >
                    Mot de passe oublié ?
                  </RouterLink>
                </div>
                <VBtn
                  block
                  type="submit"
                  name="login"
                >
                  Connexion
                </VBtn>
              </VCol>
              <VCol
                cols="12"
                class="text-center text-base"
              >
                <span>Première connexion ?</span>
                <RouterLink
                  class="text-primary ms-2"
                  :to="{ name: 'first-connexion' }"
                >
                  Activez votre compte
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
#test123 {
  font-family:Verdana, Geneva, Tahoma, sans-serif
}
</style>
